/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-02-24 16:06:37
 * @LastEditors: hutian
 * @LastEditTime: 2021-02-25 19:02:01
 */
export const YearData = [
  {
    title: '当月年预算',
    subtotal: 5000,
    January: 200,
    February: 400,
    March: 300,
    April: 400,
    May: 350,
    June: 260,
    july: 100,
    August: 400,
    September: 500,
    October: 530,
    November: 450,
    December: 600
  },
  {
    title: '当月实际达成',
    subtotal: 5000,
    January: 100,
    February: 350,
    March: 278,
    April: 410,
    May: 390,
    June: 255,
    july: 104,
    August: 305,
    September: 503,
    October: 590,
    November: 433,
    December: 598
  },
  {
    title: '达成率',
    subtotal: '82%',
    January: '50%',
    February: '87%',
    March: '92%',
    April: '100.2%',
    May: '111.4%',
    June: '98%',
    july: '104%',
    August: '76%',
    September: '100.6%',
    October: '111.3%',
    November: '96%',
    December: '99.6%'
  }
]


export const CusSumData = [
  {
    id: 1,
    title: '沈阳金客汽车配件有限公司',
    subtotal: 5000,
    January: 200,
    February: 400,
    March: 300,
    April: 400,
    May: 350,
    June: 260,
    july: 100,
    August: 400,
    September: 500,
    October: 530,
    November: 450,
    December: 600
  },
  {
    id: 2,
    title: '西宁豪华汽配部',
    subtotal: 5000,
    January: 100,
    February: 350,
    March: 278,
    April: 410,
    May: 390,
    June: 255,
    july: 422,
    August: 305,
    September: 503,
    October: 590,
    November: 433,
    December: 598
  },
  {
    id: 3,
    title: '江苏康众汽配有限公司',
    subtotal: 5000,
    January: 100,
    February: 350,
    March: 278,
    April: 410,
    May: 390,
    June: 255,
    july: 422,
    August: 305,
    September: 503,
    October: 590,
    November: 433,
    December: 598
  }
]

export const QuarterlyData = [
  {
    name: '沈阳金客汽车配件有限公司',
    total: 3000,
    rebateMoney: 200,
    noRebateMoeny: 2000,
    publicMoeny: 800
  },
  {
    name: '西宁豪华汽配部',
    total: 3000,
    rebateMoney: 200,
    noRebateMoeny: 2000,
    publicMoeny: 800
  },
  {
    name: '江苏康众汽配有限公司',
    total: 3000,
    rebateMoney: 200,
    noRebateMoeny: 2000,
    publicMoeny: 800
  }
]

export const MonthData = [
  {
    yearBudget: 10000,
    monthlyPlan: 2000,
    accumulative: 3000,
    yAchievingRate: '87%',
    mAchievingRate: '90%'
  }
]

export const DayTransactionData = [
  {
    cusName: '沈阳金客汽车配件有限公司',
    sum: 500
  }
]

export const YearSituationData = [
  {
    cusName: '沈阳金客汽车配件有限公司',
    ygoal: 400,
    contract: 50,
    accumulative: 367,
    gapYears: 20,
    contranctGap: 5,
    yreach: '89%',
    contranctReach: '92%'
  },
]