<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-02-24 15:43:19
 * @LastEditors: hutian
 * @LastEditTime: 2021-02-27 17:09:48
-->
<template>
  <div class="year_report">
    <a-table :data-source="tableData" bordered :pagination="false" :columns="columns"></a-table>
    <Chart :option="charts" :id="'1'" ref="chart"></Chart>
  </div>
</template>
<script>
  import { columns } from './components/colums.js'
  import { YearData } from './components/TableData.js'
  import Chart from './components/Chart'
  const title = {
    text: '月预算达成情况'
  }
  const xAxis = {
    categories: ['一月', '二月', '三月', '四月', '五月', '六月','七月', '八月', '九月', '十月', '十一月', '十二月']
  }
  const legend = {
    layout: 'horizontal',
    verticalAlign: 'bottom',
    borderWidth: 0
  }
  export default {
    data() {
      return {
        columns: columns,
        tableData: [],
        charts: {
          series: [
            {
              type: 'column',
              name: '当月年预算',
              data: [],
            }, 
            {
              type: 'column',
              name: '当月实际达成',
              data: [],
            }, 
            {
              type: 'spline',
              name: '达成率',
              data: []
            }, 
          ],
          xAxis: xAxis,
          legend: legend,
          title: title,
          colors: ['#FF9933', 'green', '#00FF00']
        }
      }
    },
    components: {
      Chart
    },
    
    created() {
      this.getData()
    },
    methods: {
      getData() {
        this.axios.post('/api/order/system/orderShipInfo/getMonthlyBudgetReport').then(res => {
          if(res.code == 200) {
            this.tableData = res.body
            let budget = [this.tableData[0].january,this.tableData[0].february,this.tableData[0].march,this.tableData[0].april,this.tableData[0].may,this.tableData[0].june,this.tableData[0].july,this.tableData[0].august,this.tableData[0].september,this.tableData[0].october,this.tableData[0].november,this.tableData[0].december]
            let practical = [this.tableData[1].january,this.tableData[1].february,this.tableData[1].march,this.tableData[1].april,this.tableData[1].may,this.tableData[1].june,this.tableData[1].july,this.tableData[1].august,this.tableData[1].september,this.tableData[1].october,this.tableData[1].november,this.tableData[1].december]
            let achieving = [this.tableData[2].january,this.tableData[2].february,this.tableData[2].march,this.tableData[2].april,this.tableData[2].may,this.tableData[2].june,this.tableData[2].july,this.tableData[2].august,this.tableData[2].september,this.tableData[2].october,this.tableData[2].november,this.tableData[2].december]
            this.charts.series[0].data = budget
            this.charts.series[1].data = practical
            this.charts.series[2].data = achieving
            this.$refs.chart.getData()
          }
        })
      }
    }
  }
</script>

<style lang="scss">
  
</style>
