<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-02-24 20:40:57
 * @LastEditors: hutian
 * @LastEditTime: 2021-02-27 10:44:51
-->

<template>
  <div class="x-bar">
    <div :id="id" :option="option"></div>
  </div>
</template>
<script>
// 引入highCharts模块
import HighCharts from 'highcharts'
 
export default {
  data() {
    return{
      
    }
  },
  props: {
    id: {
      type: String
    },
    option: {
      type: Object
    }
  },
  created() {

  },
  methods: {
    getData() {
      HighCharts.chart(this.id,this.option)
    }
  }
}
</script>
 
<style scoped lang='stylus'>
.x-bar{
  
}

