/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-02-24 15:46:07
 * @LastEditors: hutian
 * @LastEditTime: 2021-02-28 20:23:31
 */
export const columns = [
  {
    title: '项',
    dataIndex: 'title',
    key: 'title',
    width: 120,
    align:'center',
    // fixed: 'left',
    ellipsis:true,
  },
  {
    title: '小计',
    dataIndex: 'subtotal',
    key: 'subtotal',
    /*width:150,*/
    align:'center',
    ellipsis:true,
  },
  {
    title: '一月',
    dataIndex: 'january',
    key: 'january',
    /*width:150,*/
    align:'center',
    ellipsis:true
  },
  {
    title: '二月',
    dataIndex: 'february',
    key: 'february',
    // width:100,
    align:'center',
    ellipsis:true,
    scopedSlots: { customRender: ''}
  },
  {
    title: '三月',
    dataIndex: 'march',
    key: 'march',
    /*width:150,*/
    align:'center',
    ellipsis:true
  },
  {
    title: '四月',
    dataIndex: 'april',
    key: 'april',
    /*width:150,*/
    align:'center',
    ellipsis:true
  },
  {
    title: '五月',
    dataIndex: 'may',
    key: 'may',
    /*width:150,*/
    align:'center',
    ellipsis:true,
  },
  {
    title: '六月',
    dataIndex: 'june',
    key: 'june',
    /*width:150,*/
    align:'center',
    ellipsis:true,
  },
  {
    title: '七月',
    dataIndex: 'july',
    key: 'july',
    /*width:150,*/
    align:'center',
    ellipsis:true,
  },
  {
    title: '八月',
    dataIndex: 'august',
    key: 'august',
    // width:100,
    align:'center',
    ellipsis:true
  },
  {
    title: '九月',
    dataIndex: 'september',
    key: 'september',
    // width:100,
    align:'center',
    ellipsis:true
  },
  {
    title: '十月',
    dataIndex: 'october',
    key: 'october',
    // width:100,
    align:'center',
    ellipsis:true
  },
  {
    title: '十一月',
    dataIndex: 'november',
    key: 'november',
    // width:100,
    align:'center',
    ellipsis:true
  },
  {
    title: '十二月',
    dataIndex: 'december',
    key: 'december',
    // width:100,
    align:'center',
    ellipsis:true
  },
]

export const CusColumns = [
  {
    title: '经销商名称',
    dataIndex: 'title',
    key: 'title',
    width: 120,
    align:'center',
    // fixed: 'left',
    ellipsis:true,
  },
  {
    title: '小计',
    dataIndex: 'subtotal',
    key: 'subtotal',
    /*width:150,*/
    align:'center',
    ellipsis:true,
  },
  {
    title: '一月',
    dataIndex: 'january',
    key: 'january',
    /*width:150,*/
    align:'center',
    ellipsis:true
  },
  {
    title: '二月',
    dataIndex: 'february',
    key: 'february',
    // width:100,
    align:'center',
    ellipsis:true,
    scopedSlots: { customRender: ''}
  },
  {
    title: '三月',
    dataIndex: 'march',
    key: 'march',
    /*width:150,*/
    align:'center',
    ellipsis:true
  },
  {
    title: '四月',
    dataIndex: 'april',
    key: 'april',
    /*width:150,*/
    align:'center',
    ellipsis:true
  },
  {
    title: '五月',
    dataIndex: 'may',
    key: 'may',
    /*width:150,*/
    align:'center',
    ellipsis:true,
  },
  {
    title: '六月',
    dataIndex: 'june',
    key: 'june',
    /*width:150,*/
    align:'center',
    ellipsis:true,
  },
  {
    title: '七月',
    dataIndex: 'july',
    key: 'july',
    /*width:150,*/
    align:'center',
    ellipsis:true,
  },
  {
    title: '八月',
    dataIndex: 'august',
    key: 'august',
    // width:100,
    align:'center',
    ellipsis:true
  },
  {
    title: '九月',
    dataIndex: 'september',
    key: 'september',
    // width:100,
    align:'center',
    ellipsis:true
  },
  {
    title: '十月',
    dataIndex: 'october',
    key: 'october',
    // width:100,
    align:'center',
    ellipsis:true
  },
  {
    title: '十一月',
    dataIndex: 'november',
    key: 'november',
    // width:100,
    align:'center',
    ellipsis:true
  },
  {
    title: '十二月',
    dataIndex: 'december',
    key: 'december',
    // width:100,
    align:'center',
    ellipsis:true
  },
]

export const QuarterlyColumns = [
  {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    width: '5%',
    align:'center',
    // fixed: 'left',
    ellipsis:true,
    scopedSlots: { customRender: 'index'},
  },
  {
    title: '经销商名称',
    dataIndex: 'customerShortName',
    key: 'customerShortName',
    width: '15%',
    align:'center',
    ellipsis:true,
  },
  {
    title: '累计总销售额',
    dataIndex: 'totalSales',
    key: 'totalSales',
    width: '10%',
    align:'center',
    ellipsis:true,
    sorter: true,
    sortkey:'profit',
  },
  {
    title: '非大众产品',
    dataIndex: '',
    key: '',
    width:'60%',
    align:'center',
    ellipsis:true,
    scopedSlots: { customRender: ''},
    children: [
      {
        title: '折扣产品销售额',
        dataIndex: 'rebateSales',
        key: 'rebateSales',
        width: '30%',
        align:'center',
      },
      {
        title: '非折扣产品出货额',
        dataIndex: 'nonRebateSales',
        key: 'nonRebateSales',
        width: '30%',
        align:'center',
      },
    ]
  },
  {
    title: '大众产品销售额',
    dataIndex: 'volkswagenSales',
    key: 'volkswagenSales',
    align:'center',
    width: '10%',
    ellipsis:true
  },
]

export const MonthColumns = [
  {
    title: '交易额-万元',
    dataIndex: '',
    key: '',
    align:'center',
    ellipsis:true,
    scopedSlots: { customRender: ''},
    children: [
      {
        title: '当月年预算',
        dataIndex: 'yearPlan',
        key: 'yearPlan',
        align:'center',
      },
      {
        title: '年累计达成',
        dataIndex: 'currYearTurnover',
        key: 'currYearTurnover',
        align:'center',
      },
      {
        title: '月计划',
        dataIndex: 'monthPlan',
        key: 'monthPlan',
        align:'center',
      },
      {
        title: '当月累计达成',
        dataIndex: 'currMonthTurnover',
        key: 'currMonthTurnover',
        align:'center',
      },
    ]
  },
  {
    title: '达成率',
    dataIndex: '',
    key: '',
    align:'center',
    ellipsis:true,
    scopedSlots: { customRender: ''},
    children: [
      {
        title: '年预算达成率',
        dataIndex: 'currYearTurnoverRatio',
        key: 'currYearTurnoverRatio',
        align:'center',
      },
      {
        title: '月计划达成率',
        dataIndex: 'currMonthTurnoverRatio',
        key: 'currMonthTurnoverRatio',
        align:'center',
      },
    ]
  },
]

export const DayTransactionColumns = [
  {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    width: 120,
    align:'center',
    ellipsis:true,
    scopedSlots: { customRender: 'index'},
  },
  {
    title: '发货经销商名称',
    dataIndex: 'customerShortName',
    key: 'customerShortName',
    align:'center',
    // fixed: 'left',
    ellipsis:true,
  },
  {
    title: '交易额-万元',
    dataIndex: 'turnover',
    key: 'turnover',
    align:'center',
    // fixed: 'left',
    ellipsis:true,
  },
]

export const YearSituationColumns = [
  {
    title: '经销商名称',
    dataIndex: 'customerShortName',
    key: 'customerShortName',
    align:'center',
    ellipsis:true,
  },
  {
    title: '目标额',
    dataIndex: '',
    key: '',
    align:'center',
    ellipsis:true,
    scopedSlots: { customRender: ''},
    children: [
      {
        title: '各经销商年预算目标',
        dataIndex: 'yearPlan',
        key: 'yearPlan',
        align:'center',
      },
      {
        title: '合同起点',
        dataIndex: 'contractOrigin',
        key: 'contractOrigin',
        align:'center',
      },
    ]
  },
  {
    title: '当年累计达成',
    dataIndex: 'currYearTurnover',
    key: 'currYearTurnover',
    align:'center',
  },
  {
    title: '差距',
    dataIndex: '',
    key: '',
    align:'center',
    ellipsis:true,
    scopedSlots: { customRender: ''},
    children: [
      {
        title: '年预算差距',
        dataIndex: 'yearPlanGap',
        key: 'yearPlanGap',
        align:'center',
      },
      {
        title: '合同起点差距',
        dataIndex: 'contractOriginGap',
        key: 'contractOriginGap',
        align:'center',
      },
    ]
  },
  {
    title: '达成率',
    dataIndex: '',
    key: '',
    align:'center',
    ellipsis:true,
    scopedSlots: { customRender: ''},
    children: [
      {
        title: '年预算达成率',
        dataIndex: 'yearPlanRatio',
        key: 'yearPlanRatio',
        align:'center',
      },
      {
        title: '合同起点达成率',
        dataIndex: 'contractOriginRatio',
        key: 'contractOriginRatio',
        align:'center',
      },
    ]
  },
]